import React, {useEffect, useRef, useState} from "react";
import "https://cdnjs.cloudflare.com/ajax/libs/lightgallery-js/1.4.0/js/lightgallery.min.js";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Lightbox from 'yet-another-react-lightbox';

import {
    Captions,
    Download,
    Fullscreen,
    Thumbnails,
    Zoom,
} from 'yet-another-react-lightbox/plugins';

const AlbumComponent = (props) => {
    const {handleClick, handleOpenLink} = props;
    let animationString = "play none none none"
    const [index, setIndex] = useState(-1);
    let urls = [
        "assets/food/food-1-1.jpg",
        "assets/food/food-1-2.jpg",
        "assets/food/food-1-3.jpg",
        "assets/food/food-1-4.jpg",
        "assets/food/food-1-5.jpg",
        "assets/food/food-1-6.jpg",
        "assets/food/food-1-7.jpg",
        "assets/food/food-1-8.jpg",
        "assets/food/food-1-9.jpg",
        "assets/food/food-1-10.jpg",
        "assets/food/food-1-11.jpg",
        "assets/food/food-1-12.jpg",
        "assets/food/food-1-13.jpg",
        "assets/food/food-1-14.jpg",
        "assets/food/food-1-15.jpg",
        "assets/food/food-1-16.jpg",
        "assets/food/food-1-17.jpg",
        "assets/food/food-1-18.jpg",
        "assets/food/food-1-19.jpg",
        "assets/food/food-1-20.jpg",
        "assets/food/food-1-21.jpg",
        "assets/food/food-1-22.jpg",
        "assets/food/food-1-23.jpg",
        "assets/food/food-1-24.jpg",
        "assets/food/food-1-25.jpg",
        "assets/food/food-1-26.jpg",
        "assets/food/food-1-27.jpg",
        "assets/food/food-1-28.jpg",
        "assets/food/food-1-29.jpg",
        "assets/food/food-1-30.jpg",
    ]
    const slides = urls?.map((item, index) => {

        return {
            src: item,
            index: index,
            title: <div className={"title-light-box"}>
                <button onClick={handleClick}>
                    <span> Tisch reservieren</span>
                </button>
                <button onClick={handleOpenLink}>
                    <span> Online bestellen</span>
                </button>

            </div>,

        }
    })
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        let scrollTrigger = {
            end: 'bottom center',
            // markers:true,
            start: 'top bottom',
            toggleActions: animationString
        }
        gsap.to(".gallery-container > .gallery-item", 1.5, {
            scrollTrigger: {...scrollTrigger, trigger: ".gallery-container .gallery-item"},
            y: 0,
            opacity: 1,
            stagger: 0.1,
            duration: 0.8,
            ease: "back"
        })


    }, [])

    const onClick = (key) => {
        setIndex(key)
        console.log(index);
    }
    return (
        <div className="section-gallery">
            <Lightbox
                plugins={[Captions, Download, Fullscreen, Zoom, Thumbnails]}
                captions={{
                    showToggle: true,
                    descriptionTextAlign: 'end',
                }}
                // open={open}
                // close={() => setOpen(false)}

                index={index}
                open={index >= 0}
                close={() => setIndex(-1)}
                slides={slides}
            />
            <div className="container-sm">
                <div className="row justify-content-center">
                    <div className="gallery-container" id="animated-thumbnails-gallery">
                        {
                            slides.map((slide, key) => {

                                return (
                                    <ImgComponent src={slide?.src} index={slide.index} onClick={onClick}/>
                                )
                            })
                        }


                    </div>
                </div>
            </div>


        </div>
    )
}
const ImgComponent = ({src, onClick, index}) => {

    let imgRef = useRef();
    return (
        <a data-lg-size="1600-1067" className="gallery-item"
           onMouseOut={() => {
               let zoom = imgRef.current;
               zoom.style.setProperty('--show', "none");
           }}
           ref={imgRef}
           onClick={() => {
               onClick(index)
           }}
           onMouseMove={(event) => {

               let zoom = imgRef.current;
               let e = event;
               zoom.style.setProperty('--show', "block");
               let positionPx = e.clientX - zoom.getBoundingClientRect().left;
               let positionPy = e.clientY - zoom.getBoundingClientRect().top;
               let positionX = 100 * positionPx / zoom.offsetWidth;
               let positionY = 100 * positionPy / zoom.offsetHeight;
               zoom.style.setProperty('--show-x', positionX + "%");
               zoom.style.setProperty('--show-y', positionY + "%");
           }}
           data-src="{{$img}}"
           data-sub-html="<h2>  <a href=''><button class='button-aboutus'>Online bestellen</button></a>
                                   <a href=''><button class='button-aboutus'>Tisch reservieren</button></a></h2>">
            <img alt="layers of blue." className="img-responsive"
                 src={src}/>
            <img alt="layers of blue." className="img-responsive"
                 src={src}/>
        </a>
    )
}
export default AlbumComponent;